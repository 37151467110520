<template>
  <div></div>
</template>

<script>
export default {
  name: 'Callback',
  methods: {
    handleLoginEvent(data) {
      this.$router.push(data.state.target || "/");
    }
  },
  created() {
    let self = this;
    self.$auth.handleAuthentication()
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        if(error.error === 'invalid_token'){
          self.$router.push("/");
        }
        return error;
      });
  }
};
</script>
